import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  Divider,
  Container,
  Segment,
  Header,
  Grid,
  Icon,
  Image,
} from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

const IndexPage = (data) => {
  console.log('data index', data)
  return (
    <Layout>
      <SEO title="Home" keywords={['gatsby', 'application', 'react']} />
      <Container textAlign="center">
        <Header
          style={{ marginTop: '4em', marginBottom: '2em' }}
          icon
          as="h1"
          ali
        >
          <Icon name="heart" />
          Anita Lek
        </Header>
      </Container>
      <Divider />
      <Container>
        <Segment>
          <Header as="h2">Header</Header>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
            commodo ligula eget dolor. Aenean massa strong. Cum sociis natoque
            penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
            Nulla consequat massa quis enim. Donec pede justo, fringilla vel,
            aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut,
            imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede
            link mollis pretium. Integer tincidunt. Cras dapibus. Vivamus
            elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo
            ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam
            lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus
            viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean
            imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper
            ultricies nisi.
          </p>
        </Segment>
        <Grid  columns={3} style={{marginTop: '3em'}}>
          <Grid.Column textAlign='center' verticalAlign='middle'>
            <Header  as="h2">Mijn katten:</Header>
          </Grid.Column>
          <Grid.Column >
            <Image src={data.data.kat1.childImageSharp.fixed.src} circular centered size='large'/>
          </Grid.Column>
          <Grid.Column >
            <Image src={data.data.kat2.childImageSharp.fixed.src} circular centered size='large' />
          </Grid.Column>
        </Grid>

      </Container>
    </Layout>
  )
}

export const fixedImage = graphql`
  fragment fixedImage on File {
    childImageSharp {
      fixed(width: 300, height: 300) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`
export const pageQuery = graphql`
  query {
    kat2: file(relativePath: { eq: "katten/kat2.jpg" }) {
      ...fixedImage
    }
    kat1: file(relativePath: { eq: "katten/kat1.jpg" }) {
      ...fixedImage
    }
  }
`

export default IndexPage
